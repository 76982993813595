import { DateFormat, SearchBox, colour } from '@a-cloud-guru/kermit';
import { Radio, Button, Tooltip, Icon } from 'antd';
import React, { useReducer, useState } from 'react';
import styled from 'styled-components';

import { DisabledWhenReadOnly } from '/components/auth/DisabledWhenReadOnly';
import { Box, Flex } from '/components/box';
import { PaginatedTable } from '/components/table/PaginatedTable';
import { useAlgoliaBrowseAll } from '/hooks/use-algolia-browse-all';
import { useOrganisationInvitationQuery } from '/data/query-hooks';
import { ExportButton } from './ExportButton';
import { ResendInvite } from './ResendInvite';
import { RevokeInvite } from './RevokeInvite';
import { ACTION_TYPES, INITIAL_STATE, reducer } from './reducer';
import { isReadOnly } from '../../../hooks/isReadOnly';

const PAGE_SIZE = 10;
const currentDate = new Date().valueOf();

const getInvitationStatus = ({ acceptedAt, validUntil }) => {
  if (acceptedAt) {
    return 'Joined';
  }

  if (currentDate < validUntil) {
    return 'Pending';
  }

  return 'Expired';
};

const FILTERS = {
  all: 'valid_until > 0',
  joined: 'accepted_at > 0',
  pending: `valid_until > ${currentDate} AND accepted_at = 0`,
  expired: `valid_until < ${currentDate} AND accepted_at = 0`,
};

const OrganisationInvitations = ({ organisationId }) => {
  const [
    { query, page, filter, resendEmails, revokedEmails },
    dispatch,
  ] = useReducer(reducer, INITIAL_STATE);
  
  const [key, setKey] = useState(0);
  const readOnly = isReadOnly();

  const { browse } = useAlgoliaBrowseAll({
    type: 'invitation',
    filters: FILTERS[filter],
  });

  const {
    data,
    pagination: { total, pageSize },
    loading,
  } = useOrganisationInvitationQuery({
    query,
    page,
    pageSize: PAGE_SIZE,
    status: filter,
    key: key,
  });

  const generateColumns = () => [
    {
      title: 'Email Address',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Invitation Code',
      key: 'invitation_code',
      render: ({ invitation_code, override_join_up }) => {
        return <>
          {invitation_code}
          {override_join_up && <Tooltip title="Override Join Up"><Icon type="exclamation-circle" style={{ marginLeft: 4 }} /></Tooltip>}
        </>
      }
    },
    {
      title: 'Invitation Sent',
      dataIndex: 'sent_at',
      render: (sentAt) =>
        sentAt && (
          <DateFormat format="D MMMM YYYY, H:mm A">{sentAt}</DateFormat>
        ),
    },
    {
      title: 'Admin',
      render: ({ invite_as_admin }) => (
        <Flex justifyContent="center">{invite_as_admin ? '✅' : '❌️'}</Flex>
      ),
    },
    {
      title: 'Status',
      render: ({ valid_until, accepted_at, email }) => (
        <InvitationStatus>
          {(resendEmails.includes(email) && 'Resent') ||
            (revokedEmails.includes(email) && 'Revoked') ||
            getInvitationStatus({
              acceptedAt: accepted_at,
              validUntil: valid_until,
            })}
        </InvitationStatus>
      ),
    },
    {
      title: 'Actions',
      render: ({ invitation_code, email, accepted_at, valid_until }) => {
        const status = getInvitationStatus({
          acceptedAt: accepted_at,
          validUntil: valid_until,
        });

        if (status !== 'Joined') {
          return (
            <>
              <DisabledWhenReadOnly>
                <ResendInvite
                  invitationCode={invitation_code}
                  email={email}
                  refreshIndex={() => {
                    if (!readOnly) dispatch({ type: ACTION_TYPES.RELOAD });
                  }}
                  addResentEmail={({ email }) => {
                    if (!readOnly)
                      dispatch({
                        type: ACTION_TYPES.RESEND_EMAIL,
                        payload: { email },
                      });
                  }}
                />
              </DisabledWhenReadOnly>
              {status === 'Pending' && (
                <DisabledWhenReadOnly>
                  <RevokeInvite
                    invitationCode={invitation_code}
                    email={email}
                    refreshIndex={() => {
                      if (!readOnly) dispatch({ type: ACTION_TYPES.RELOAD });
                    }}
                    addRevokedEmail={({ email }) => {
                      if (!readOnly)
                        dispatch({
                          type: ACTION_TYPES.REVOKE_EMAIL,
                          payload: { email },
                        });
                    }}
                  />
                </DisabledWhenReadOnly>
              )}
            </>
          );
        }
      },
    },
  ];

  return (
    <Box mt="-64px">
      <Flex>
        <Flex flexDirection="column" mr="24px" flex="1">
          <div>
            <StatusTitle>Invitation Status</StatusTitle>
            <StatusRadios
              value={filter}
              onChange={(e) =>
                dispatch({
                  type: ACTION_TYPES.UPDATE_FILTER,
                  payload: { value: e.target.value },
                })
              }
            >
              <Radio value="all">All</Radio>
              <Radio value="joined">Joined</Radio>
              <Radio value="pending">Pending</Radio>
              <Radio value="expired">Expired</Radio>
            </StatusRadios>
          </div>
        </Flex>
        <Box flex="5">
          <Flex justifyContent="space-between" mb="14px">
            <SearchBox
              placeholder="Search Email Addresses"
              onChange={(e) =>
                dispatch({
                  type: ACTION_TYPES.UPDATE_QUERY,
                  payload: { value: e.target.value },
                })
              }
              value={query}
            />

            <Flex justifyContent="flex-end">
              <Box mr="8px">
                <ExportButton onExport={browse} />
              </Box>
              <Box ml="8px">
                {loading && <RefreshLoading type="reload" spin />}
                {!loading && (
                  <Tooltip title="Refresh Invitations">
                    <RefreshButton
                      icon="reload"
                      onClick={() => setKey(key + 1)}
                    />
                  </Tooltip>
                )}
              </Box>
            </Flex>
          </Flex>
          <InvitationTable
            loading={loading}
            pagination={{
              current: page,
              pageSize,
              total,
              onChange: (value) =>
                dispatch({
                  type: ACTION_TYPES.UPDATE_STATE,
                  payload: {
                    key: 'page',
                    value,
                  },
                }),
            }}
            columns={generateColumns()}
            dataSource={data}
            rowKey={({ email }) => email}
          />
        </Box>
      </Flex>
    </Box>
  );
};

const InvitationTable = styled(PaginatedTable)`
  .ant-table-thead > tr > th:nth-child(4) {
    text-align: center;
  }
`;

const StatusTitle = styled.div`
  font-size: 14px;
  font-weight: 600;
  color: ${colour.gray900};
`;

const StatusRadios = styled(Radio.Group)`
  display: flex;
  flex-direction: column;

  .ant-radio-wrapper-checked {
    font-weight: 600;
    color: ${colour.gray600};
  }

  label {
    margin: 2px 0 0;
    color: ${colour.gray500};

    .ant-radio-inner:after {
      background-color: ${colour.blueLight};
    }

    .ant-radio-checked .ant-radio-inner {
      border-color: ${colour.blueLight};
    }
  }
`;

const InvitationStatus = styled.span`
  color: ${(props) => {
    switch (props.children) {
      case 'Joined':
        return colour.green;
      case 'Pending':
        return colour.orange;
      case 'Expired':
        return colour.red;
      case 'Resent':
        return colour.blueLight;
      case 'Revoked':
        return colour.red;
      default:
        return null;
    }
  }};
  font-weight: 500;
  text-transform: capitalize;
  word-break: keep-all;
`;

const RefreshButton = styled(Button)`
  min-width: auto;
`;

const RefreshLoading = styled(Icon)`
  min-width: 32px;
  padding-top: 9px;
`;

export { OrganisationInvitations };