import { Tabs } from 'antd';
import gql from 'graphql-tag';
import get from 'lodash.get';
import React, { useEffect, useState } from 'react';
import useWebSocket from 'react-use-websocket';
import { Query } from 'react-apollo';
import { Helmet } from 'react-helmet';
import { Route } from 'react-router-dom';
import styled from 'styled-components';
import { rgba } from 'polished';

import { OrganisationDetails } from './OrganisationDetails/OrganisationDetails';
import { OrganisationUsers } from './OrganisationUsers/OrganisationUsers';
import { OrganisationReports } from './OrganisationReports/OrganisationReports';
import { OrganisationInvitations } from './OrganisationInvitations/OrganisationInvitations';
import { OrganisationStudyGroups } from './OrganisationStudyGroups';
import { OrganisationReportingGroups } from './OrganisationReportingGroups';
import { OrganisationLearningPaths } from './OrganisationLearningPaths';
import { OrganisationTeams } from './OrganisationTeams';
import { AdminLayout } from '/layouts/AdminLayout';
import { Masthead } from '/components/masthead';
import { colour } from '/styles/variables';
import { useApolloClient } from '/lib/hooks';
import Algolia from '/providers/algolia';
import { authService } from '../../services/auth';

const GET_ORGANISATION = gql`
  query getOrganisation($organisationId: String) {
    ACG_organisation(orgId: $organisationId) {
      id
      name
      chargeModel
      domains
      joinCodes
      website
      createdAt
      archivedAt
      accountType
      connectionName
      companyCode
      disableInvitations
      isReseller
      subscription {
        startDate
        endDate
        userLicenseCount
        endDateOverride
        endDateOverrideReason
      }
      features {
        allowInvitations
        allowUserReactivationViaSAML
        requireEmployeeId
        restrictSocial
        restrictProfileEdit
        trackLicenseReallocations
      }
      consumedLicenses
      deactivated
      deactivatedLicenses
      entitlement {
        customerIdentifier
        productCode
        marketplaceProvider
      }
      betaFeatureRequests {
        displayName
        feature
        status
      }
      licenseReallocationStats {
        reallocationUsage
        reallocationThreshold
        reallocationUsagePercentage
      }
      reallocationTrackingConfig {
        startDate
        endDate
        licensePercentage
      }
    }
  }
`;

const Organisation = (props) => {
  const organisationId = get(props, 'serverState.match.params.organisationId');
  const apolloClient = useApolloClient();
  const [ socketUrl, setSocketUrl ] = useState(null);

  const {
    getWebSocket
  } = useWebSocket(socketUrl, {
    onError: (error) => console.log(error)
  });

  useEffect(() => {
    (async () => {
      const token = await authService.getToken();
      setSocketUrl(`wss://p8sn7h6fse.execute-api.us-east-1.amazonaws.com/test?Auth=${token}`);
    })();
    return () => getWebSocket().close();
  }, []);

  return (
    <Query query={GET_ORGANISATION} variables={{ organisationId }} notifyOnNetworkStatusChange={true}>
      {({ loading, data, error, refetch }) => {
        const organisation = get(data, 'ACG_organisation') || {};
        const errorTitle = error ? 'Something broke' : '';
        const loadingTitle = loading ? 'Loading' : '';
        const title = errorTitle || loadingTitle || get(organisation, 'name');

        return (
          <Algolia organisationId={organisationId} apolloClient={apolloClient}>
            <AdminLayout masthead={<Masthead title={title} navigation />}>
              <Helmet>
                <title>{organisation.name}</title>
              </Helmet>
              <Route
                exact
                render={(nestedProps) => {
                  const organisationId = get(
                    nestedProps,
                    'match.params.organisationId'
                  );
                  const currentTab = props.serverState.match.params.subroute;

                  return (
                    <Container>
                      <StyledTabs
                        onTabClick={(e) =>
                          nestedProps.history.push(
                            `/organizations/${organisationId}/${e}`
                          )
                        }
                        activeKey={currentTab}
                        animated={false}
                      >
                        <Tabs.TabPane tab="Details" key="details">
                          <OrganisationDetails loading={loading} refetch={refetch} websocket={getWebSocket()} {...organisation} />
                        </Tabs.TabPane>
                        <Tabs.TabPane tab="Users" key="users">
                          <OrganisationUsers
                            organisationId={organisationId}
                            active={!get(data, 'ACG_organisation.deactivated')}
                          />
                        </Tabs.TabPane>
                        <Tabs.TabPane tab="Reports" key="reports">
                          <OrganisationReports
                            organisationId={organisationId}
                            organisation={organisation}
                            chargeModel={
                              get(data, 'ACG_organisation.chargeModel') || ''
                            }
                            option={get(
                              props,
                              'serverState.match.params.option'
                            )}
                            setSubroute={(e) =>
                              nestedProps.history.push(
                                `/organizations/${organisationId}/reports/${e}`
                              )
                            }
                          />
                        </Tabs.TabPane>
                        <Tabs.TabPane tab="Invitations History" key="invitations">
                          <OrganisationInvitations
                            organisationId={organisationId}
                          />
                        </Tabs.TabPane>
                        <Tabs.TabPane tab="Study Groups" key="study-groups">
                          <OrganisationStudyGroups
                            organisationId={organisationId}
                          />
                        </Tabs.TabPane>
                        <Tabs.TabPane tab="Learning Paths" key="learning-paths">
                          <OrganisationLearningPaths
                            organisationId={organisationId}
                          />
                        </Tabs.TabPane>
                        <Tabs.TabPane
                          tab="Reporting Groups"
                          key="reporting-groups"
                        >
                          <OrganisationReportingGroups
                            organisationId={organisationId}
                          />
                        </Tabs.TabPane>
                        <Tabs.TabPane tab="Teams" key="teams">
                          <OrganisationTeams organisationId={organisationId} />
                        </Tabs.TabPane>
                      </StyledTabs>
                    </Container>
                  );
                }}
              />
            </AdminLayout>
          </Algolia>
        );
      }}
    </Query>
  );
};

const Container = styled.div`
  position: relative;
  top: -65px;
`;

const StyledTabs = styled(Tabs)`
  display: flex;
  flex-direction: column;
  .ant-tabs-bar {
    margin: 0 0 100px;
    border-bottom: none;
    :hover {
      color: #fff;
    }
  }
  .ant-tabs-nav {
    line-height: 1;
    padding: 2px 0;
    color: ${rgba('#fff', 0.7)};
  }
  .ant-tabs-tab:hover {
    color: #fff;
  }
  .ant-tabs-tab-active {
    color: #fff;
    font-weight: 400;
  }
  .ant-tabs-ink-bar {
    background-color: ${colour.orange};
  }
`;

export { Organisation };
